<template></template>
<script>
export default {
  props: {
    google: Object,
    map: Object,
    position: Object,
  },
  data() {
    return { marker: null };
  },
  methods: {
    placePin(position) {
      const { Marker } = this.google.maps;
      if (this.marker) {
        this.marker.setPosition(position);
        // this.position = position;
      } else {
        this.marker = new Marker({
          position: position,
          map: this.map,
        });
      }
      //   console.log(this.marker.getPosition().lat());
      //   var lng = this.marker.getPosition().lng();
      //   this.marker.getPosition()
      //   this.$emit("updatePin", this.position);
    },
  },
  watch: {
    position: function (val) {
      this.placePin(val);
      this.map.panTo(val);
    },
  },
  mounted() {
    if (this.position.lat !== null && this.position.lng !== null) {
      this.placePin(this.position);
    }
    this.google.maps.event.addListener(this.map, "click", (event) => {
      this.placePin(event.latLng);
      this.$emit("updatePin", event.latLng);
    });
  },
};
</script>