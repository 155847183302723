<template>
  <map-loader :map-config="mapConfig" :lat="getLat" :lng="getLng" mapHeight="32rem" class="focus:tw-outline-none">
    <template slot-scope="scopeProps">
      <map-pin :google="scopeProps.google" :map="scopeProps.map" :position="{lng: lng, lat: lat}" @updatePin="updatePin"/>
    </template>
  </map-loader>
</template>

<script>
import axios from "axios";
import MapLoader from "@/components/Map/MapLoader.vue";
import MapPin from "@/components/Map/MapPin";
import MapSettings from "@/lib/mapSettings";

export default {
  name: "MapInput",
  components: {
    MapLoader,
    MapPin
  },
  props: ["lat", "lng"],
  data() {
    return {};
  },
  computed: {
    mapConfig() {
      return {
        ...MapSettings(),
        zoom: 15,
        mapTypeControl: false,
        scaleControl: true,
        zoomControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        // position: {lat: this.$props.lat, lng: this.$props.lng}
      };
    },
    getLat() {
      if (this.$props.lat !== null) {
        return this.$props.lat;
      } else {
        //   Default to sydney lol
        return -33.865143;
      }
    },
    getLng() {
      if (this.$props.lng !== null) {
        return this.$props.lng;
      } else {
        //   Default to sydney lol
        return 151.2099;
      }
    },
  },
  methods: {
    // gpsLocation() {
    //   var location = this.$props.lat + ", " + this.$props.long
    //   console.log(location)
    //   return location
    // },
    updatePin(value) {
        this.$emit('updateLocation', value)
    }
  }
};
</script>

<style scoped>
#map {
  height: 100px;
  width: 100%;
}
</style>
